import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.js'
import '@popperjs/core'
import * as Sentry from '@sentry/vue';

Sentry.init({
	Vue: Vue,
	dsn: "https://d45e58b649b54f35975c6592f88caae8@sentry.nerivon.com:9000/10",
	logErrors: true,
});

Sentry.setContext("app", {
	app_name: "Client Portal"
});

Vue.config.productionTip = false
Vue.use(BootstrapVue)
import jQuery from 'jquery'

Vue.mixin({
	data()
	{
		var url = "https://api.mitchellwhale.com/api";

		// if(String(window.location).indexOf(":8080") !== -1)
		// {
		// 	url = "http://localhost:5000"
		// }

		return {
			editing: false,
			api_server: url,
			access_token: "xsdm8239kdsf328sjdhfkjsdlkjdsklf23093223n32n23ljka342kjdslkj2304",
			loading_count: 0
		}
	},
	methods:
	{
		CORS(request_type, request_url, request_data, success_callback, fail_callback)
		{
			var access_token = this.access_token;
			var session_id = localStorage.getItem("session_id");

			jQuery.ajax(
			{
				type: request_type,
				url: this.api_server + request_url,
				data: request_data,
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true,
				beforeSend: function(xhr) {
					xhr.setRequestHeader("Authorization", access_token + "|" + session_id);
				}
			}).done(function(data)
			{
				if(typeof success_callback == "function")
				{
					success_callback(data);
				}
			}).fail(function(data)
			{
				if(typeof fail_callback == "function")
				{
					fail_callback(data);
				}
			});
		},
		retina: function(src)
		{
			if(window.devicePixelRatio == 2)
			{
				return src.replace(/\.(png|PNG|jpe?g|JPE?G)$/, "@2x.$1");
			}
			else
			{
				return src;
			}
		},
		close: function()
		{
			this.$router.go(-1);
		},
		closeModals: function()
		{
			jQuery(".modal").each(function()
			{
				var me = jQuery(this);

				if(me.hasClass("fade"))
				{
					me.removeClass("fade");
					me.modal("hide");
					me.addClass("fade");
				}
				else
				{
					me.modal("hide");
				}
			});
		},
		nerivon_confirm: function(title, message, type, close_after, callback)
		{
			// var vm = this;
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			if(type == "warning")
			{
				icon = '<em class="fas fa-exclamation-circle text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="fas fa-exclamation-circle text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="fas fa-check-circle text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="fas fa-info-circle text-info"></em>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 0">No</button> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Yes</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			var vm = this;

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				// Make sure all modals are closed. Sometimes the backdrop gets stuck.
				vm.closeModals();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_alert: function(title, message, type, close_after, callback)
		{
			// var vm = this;
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function(isConfirm)
				{
					return isConfirm;
				};
			}

			if(type == "warning")
			{
				icon = '<em class="fas fa-exclamation-circle text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="fas fa-exclamation-circle text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="fas fa-check-circle text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="fas fa-info-circle text-info"></em>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_input: function(title, message, close_after, callback)
		{
			// var vm = this;
			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title"><em class="fas fa-keyboard"></em> ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '<br><br><input id="nvModalInput" class="form-control" autofocus /></div> \
						<div class="modal-footer text-center"> \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 0">Cancel</button> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = document.getElementById(\'nvModalInput\').value">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 0 ? false : nvModalAnswer));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivon_choice: function(title, message, type, choices, close_after, callback)
		{
			// var vm = this;
			var icon = "";
			var choices_html = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			if(type == "warning")
			{
				icon = '<em class="fas fa-exclamation-circle text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="fas fa-exclamation-circle text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="fas fa-check-circle text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="fas fa-info-circle text-info"></em>';
			}

			for(var i=0; i<choices.length; i++)
			{
				choices_html += '<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = \'' + choices[i][0] + '\'">' + choices[i][1] + '</button>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" />' + choices_html + ' \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = \'\'">Cancel</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback(nvModalAnswer);
			});

			jQuery("#nvModal").modal("show");
		},
		showWarning: function(message, text, close_after, callback)
		{
			this.nerivon_confirm(message, text, "warning", close_after, callback);
		},
		showError: function(message, text, close_after, callback)
		{
			this.nerivon_alert(message, text, "error", close_after, callback);
		},
		showSuccess: function(message, text, close_after, callback)
		{
			this.nerivon_alert(message, text, "success", close_after, callback);
		},
		showInfo: function(message, text, close_after, callback)
		{
			this.nerivon_alert(message, text, "info", close_after, callback);
		},
		startLoading: function()
		{
			this.loading_count++;
			jQuery(".loading").addClass("fa-circle-notch fa-spin");
			// this.showToast("<em class='fas fa-circle-notch fa-spin'></em> Please Wait...", this.loading_count, (this.loading_count == 1 ? "1 item is" : this.loading_count + " items are") + " processing.", false);
		},
		number_format(number, decimals, dec_point, thousands_sep)
		{
			// http://kevin.vanzonneveld.net
			// +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
			// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// +     bugfix by: Michael White (http://getsprink.com)
			// +     bugfix by: Benjamin Lupton
			// +     bugfix by: Allan Jensen (http://www.winternet.no)
			// +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
			// +     bugfix by: Howard Yeend
			// +    revised by: Luke Smith (http://lucassmith.name)
			// +     bugfix by: Diogo Resende
			// +     bugfix by: Rival
			// +      input by: Kheang Hok Chin (http://www.distantia.ca/)
			// +   improved by: davook
			// +   improved by: Brett Zamir (http://brett-zamir.me)
			// +      input by: Jay Klehr
			// +   improved by: Brett Zamir (http://brett-zamir.me)
			// +      input by: Amir Habibi (http://www.residence-mixte.com/)
			// +     bugfix by: Brett Zamir (http://brett-zamir.me)
			// +   improved by: Theriault
			// +      input by: Amirouche
			// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// *     example 1: number_format(1234.56);
			// *     returns 1: '1,235'
			// *     example 2: number_format(1234.56, 2, ',', ' ');
			// *     returns 2: '1 234,56'
			// *     example 3: number_format(1234.5678, 2, '.', '');
			// *     returns 3: '1234.57'
			// *     example 4: number_format(67, 2, ',', '.');
			// *     returns 4: '67,00'
			// *     example 5: number_format(1000);
			// *     returns 5: '1,000'
			// *     example 6: number_format(67.311, 2);
			// *     returns 6: '67.31'
			// *     example 7: number_format(1000.55, 1);
			// *     returns 7: '1,000.6'
			// *     example 8: number_format(67000, 5, ',', '.');
			// *     returns 8: '67.000,00000'
			// *     example 9: number_format(0.9, 0);
			// *     returns 9: '1'
			// *    example 10: number_format('1.20', 2);
			// *    returns 10: '1.20'
			// *    example 11: number_format('1.20', 4);
			// *    returns 11: '1.2000'
			// *    example 12: number_format('1.2000', 3);
			// *    returns 12: '1.200'
			// *    example 13: number_format('1 000,50', 2, '.', ' ');
			// *    returns 13: '100 050.00'
			// Strip all characters but numerical ones.
			number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
			var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function (n, prec)
			{
				var k = Math.pow(10, prec);
				return '' + Math.round(n * k) / k;
			};
			// Fix for IE parseFloat(0.55).toFixed(0) = 0;
			s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
			if (s[0].length > 3)
			{
				s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
			}
			if ((s[1] || '').length < prec)
			{
				s[1] = s[1] || '';
				s[1] += new Array(prec - s[1].length + 1).join('0');
			}
			return s.join(dec);
		},
		stopLoading: function()
		{
			this.loading_count--;

			if(this.loading_count < 0)
			{
				this.loading_count = 0;
			}

			if(this.loading_count == 0)
			{
				jQuery(".loading").removeClass("fa-circle-notch fa-spin");
				// jQuery("#toast").toast("hide");
			}
			// else
			// {
			// 	this.showToast("<em class='fas fa-circle-notch fa-spin'></em> Please Wait...", this.loading_count, (this.loading_count == 1 ? "1 item is" : this.loading_count + " items are") + " processing.", false);
			// }
		},
		getIcon: function(buscode)
		{
			var icon = "fa-fw ";
			buscode = buscode.toUpperCase();

			if(buscode == "HOME")
			{
				icon += "fas fa-home";
			}
			else if(buscode == "AUTO")
			{
				icon += "fas fa-car";
			}
			else if(buscode == "ACAN")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "ACCI")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "ANNU")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "AUTOR")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "AUTX")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "BIP")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "BOAT")
			{
				icon += "fas fa-ship";
			}
			else if(buscode == "BOND")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "BUILD")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "CAUTO")
			{
				icon += "fas fa-car";
			}
			else if(buscode == "CCOM")
			{
				icon += "far fa-building";
			}
			else if(buscode == "CIPSP")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "CIPST")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "COM2")
			{
				icon += "far fa-building";
			}
			else if(buscode == "COM3")
			{
				icon += "far fa-building";
			}
			else if(buscode == "COMM")
			{
				icon += "far fa-building";
			}
			else if(buscode == "COMMA")
			{
				icon += "far fa-building";
			}
			else if(buscode == "COMMX")
			{
				icon += "far fa-building";
			}
			else if(buscode == "CON2")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "COND")
			{
				icon += "fas fa-building";
			}
			else if(buscode == "CRIME")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "D&O")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "DIS")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "DWEL")
			{
				icon += "fas fa-house";
			}
			else if(buscode == "E&O")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "FARM")
			{
				icon += "fas fa-house";
			}
			else if(buscode == "FIRE")
			{
				icon += "fas fa-fire";
			}
			else if(buscode == "GARAG")
			{
				icon += "fas fa-warehouse";
			}
			else if(buscode == "HABL")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "HEAL")
			{
				icon += "fas fa-heartbeat";
			}
			else if(buscode == "HOM2")
			{
				icon += "fas fa-home";
			}
			else if(buscode == "LEGAL")
			{
				icon += "fas fa-gavel";
			}
			else if(buscode == "LIAB")
			{
				icon += "fas fa-gavel";
			}
			else if(buscode == "LIFE")
			{
				icon += "far fa-life-ring";
			}
			else if(buscode == "MCYCL")
			{
				icon += "fas fa-motorcycle";
			}
			else if(buscode == "MOBIL")
			{
				icon += "fas fa-trailer";
			}
			else if(buscode == "PACK")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "PAL")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "PROPX")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "RRIF")
			{
				icon += "fas fa-dollar-sign";
			}
			else if(buscode == "RRSP")
			{
				icon += "fas fa-dollar-sign";
			}
			else if(buscode == "SEAS")
			{
				icon += "fas fa-leaf";
			}
			else if(buscode == "SNOW")
			{
				icon += "fas fa-snowflake";
			}
			else if(buscode == "TCAN")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "TEN2")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "TENT")
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}
			else if(buscode == "TRAIL")
			{
				icon += "fas fa-trailer";
			}
			else if(buscode == "TRAV")
			{
				icon += "fas fa-globe";
			}
			else if(buscode == "UMBR")
			{
				icon += "fas fa-umbrella";
			}
			else
			{
				icon += "far fa-address-book fa-flip-horizontal";
			}

			return icon;
		}
	}
});


function cleandate(input)
{
	if(input instanceof Date)
	{
		try
		{
			input = input.toISOString();
		}
		catch(e)
		{
			return;
		}
	}

	if(typeof input == "undefined" || input == null || input == "0000-00-00 00:00:00" || input == "0000-00-00")
	{
		return "";
	}

	try
	{
		// The first two : are part of the date. For some reason EXIF data uses yyyy:mm:dd hh:mm:ss.
		input = input.replace(" ", "T").replace(/([0-9]{4}):([0-9]{2}):([0-9]{2})T(.*)/, "$1-$2-$3T$4");
	}
	catch(e)
	{
		return "";
	}

	return input;
}

var long_months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var short_months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

Vue.filter('maskedPhone', function(phone)
{
	if(phone == null)
	{
		return "";
	}
	return phone.replace(/[0-9]{6}/g, "***-***-");
});

Vue.filter('maskedEmail', function(email)
{
	if(email == null)
	{
		return "";
	}
	return email.replace(/[A-Za-z]*@(.*)/g, "*****@$1");
});

Vue.filter('shortDate', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned != "")
	{
		var d = new Date(cleaned);
		return short_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
	}
	else
	{
		return "N/A";
	}
});

Vue.filter('longDate', function(input)
{
	var cleaned = cleandate(input);

	if(cleaned != "")
	{
		var d = new Date(cleaned);
		return long_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
	}
	else
	{
		return "N/A";
	}
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
