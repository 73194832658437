<template>
	<div id="app">
		<header class="container-fluid p-0 mb-3">
			<nav class="navbar navbar-expand navbar-light bg-light d-flex justify-content-between">
				<a class="navbar-brand" href="https://mitchellwhale.com" target="_blank"><img :src="retina('/images/logo.jpg')" style="width: 205px; height: 20px;" class="mw-100 h-auto" /></a>
				<button type="button" class="btn d-block d-lg-none" v-b-toggle.sidebar-backdrop v-if="logged_in">
					<span class="navbar-toggler-icon"></span>
				</button>

				<b-sidebar id="sidebar-backdrop" class="niceblue" title="" backdrop-variant="dark" backdrop right shadow>
					<div class="p-3">
						<fieldset v-if="policies.length > 1">
							<legend>Switch Policy</legend>

							<ul class="nav">
								<li class="nav-item d-block w-100" v-for="p in policies" :key="p.policy_id">
									<a href="" @click.prevent="setPolicy(p)" class="nav-link nowrap"><em :class="getIcon(p.buscode)"></em> {{p.policynum}}</a>
								</li>
							</ul>
						</fieldset>

						<fieldset>
							<legend>Policy {{active_policy}}</legend>

							<ul class="nav">
								<li class="nav-item d-block w-100" v-show="active_policy">
									<router-link :to="'/documents/' + active_policy" class="nav-link nowrap"><em class="far fa-fw fa-file-pdf"></em> Documents</router-link>
								</li>
								<li class="nav-item d-block w-100" v-show="active_policy">
									<router-link :to="'/pinkslips/' + active_policy" class="nav-link nowrap"><em class="far fa-fw fa-id-card pinkslip"></em> Pink Slips</router-link>
								</li>
							</ul>
						</fieldset>

						<fieldset>
							<legend>Other</legend>

							<ul class="nav">
								<li class="nav-item d-block w-100">
									<router-link to="/numbers" class="nav-link nowrap"><em class="fas fa-fw fa-info-circle"></em> Company Numbers</router-link>
								</li>
								<li class="nav-item d-block w-100">
									<router-link to="/account" class="nav-link nowrap"><em class="far fa-fw fa-user"></em> My Account</router-link>
								</li>
								<li class="nav-item d-block w-100">
									<router-link to="/logout" class="nav-link nowrap"><em class="fas fa-fw fa-sign-out-alt"></em> Logout</router-link>
								</li>
							</ul>
						</fieldset>
					</div>
				</b-sidebar>

				<div class="d-none d-lg-block" id="navbarSupportedContent" v-if="logged_in">
					<ul class="navbar-nav text-right">
						<li class="nav-item dropdown" v-if="policies.length > 1">
							<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><em class="far fa-fw fa-address-book fa-flip-horizontal"></em> Policies</a>
							<div class="dropdown-menu">
								<a href="" @click.prevent="setPolicy(p)" class="dropdown-item" v-for="p in policies" :key="p.policy_id"><em :class="getIcon(p.buscode)"></em> {{p.policynum}} ({{p.insurance_company}})</a>
							</div>
						</li>
						<li class="nav-item" v-else-if="policies.length == 1">
							<router-link :to="'/policy/' + active_policy" class="nav-link nowrap"><em :class="getIcon(policies[0].buscode)"></em> Policy Info</router-link>
						</li>
						<li class="nav-item" v-show="active_policy">
							<router-link :to="'/documents/' + active_policy" class="nav-link nowrap"><em class="far fa-fw fa-file-pdf"></em> Documents</router-link>
						</li>
						<li class="nav-item" v-show="active_policy">
							<router-link :to="'/pinkslips/' + active_policy" class="nav-link nowrap"><em class="far fa-fw fa-id-card pinkslip"></em> Pink Slips</router-link>
						</li>
						<li class="nav-item">
							<router-link to="/numbers" class="nav-link nowrap"><em class="fas fa-fw fa-info-circle"></em> Company Numbers</router-link>
						</li>
						<li class="nav-item">
							<router-link to="/account" class="nav-link nowrap"><em class="far fa-fw fa-user"></em> My Account</router-link>
						</li>
						<li class="nav-item">
							<router-link to="/logout" class="nav-link nowrap"><em class="fas fa-fw fa-sign-out-alt"></em> Logout</router-link>
						</li>
					</ul>
				</div>
			</nav>
			<nav class="niceblue text-center d-flex justify-content-center" v-if="logged_in">
				<ul class="nav">
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><em class="far fa-fw fa-address-book fa-flip-horizontal"></em> {{active_policy}}</a>
						<div class="dropdown-menu">
							<a href="" @click.prevent="setPolicy(p)" class="dropdown-item" v-for="p in policies" :key="p.policy_id"><em :class="getIcon(p.buscode)"></em> {{p.policynum}}</a>
						</div>
					</li>
				</ul>
			</nav>
		</header>

		<div class="container mb-5"><router-view :session_id="session_id" :logged_in="logged_in" @setlogin="setLogin" @setsessionid="setSessionID" /></div>

		<footer class="container-fluid p-0" v-if="logged_in">
			<div style="height: 100px;"><!--spacer--></div>

			<nav class="fixed-bottom">
				<hr>
				<ul class="nav d-flex justify-content-center">
					<li class="nav-item" v-if="policies.length == 1">
						<router-link :to="'/policy/' + active_policy" class="nav-link text-center"><em class="far fa-lg fa-address-book fa-flip-horizontal"></em><br>Policy</router-link>
					</li>
					<li class="nav-item" v-show="active_policy">
						<router-link :to="'/documents/' + active_policy" class="nav-link text-center"><em class="far fa-lg fa-file-pdf"></em><br>Documents</router-link>
					</li>
					<li class="nav-item" v-show="active_policy">
						<router-link :to="'/pinkslips/' + active_policy" class="nav-link text-center"><em class="far fa-lg fa-id-card pinkslip"></em><br>Pink Slips</router-link>
					</li>
					<li class="nav-item d-none d-md-block">
						<router-link to="/numbers" class="nav-link text-center"><em class="fas fa-fw fa-info-circle"></em><br>Other</router-link>
					</li>
					<li class="nav-item d-none d-md-block">
						<router-link to="/account" class="nav-link text-center"><em class="far fa-user"></em><br>Account</router-link>
					</li>
					<li class="nav-item d-none d-md-block">
						<router-link to="/logout" class="nav-link text-center"><em class="fas fa-sign-out-alt"></em><br>Logout</router-link>
					</li>
				</ul>
			</nav>
		</footer>
	</div>
</template>

<script>
	export default
	{
		name: 'App',
		data() {
			return {
				logged_in: (localStorage.getItem("session_id") != ""),
				session_id: localStorage.getItem("session_id"),
				active_policy: null,
				policies: []
			}
		},
		methods: {
			checkSession: function()
			{
				var vm = this;

				if(!this.logged_in)
				{
					vm.setLogin(false);
					vm.setSessionID("");

					if(vm.$route.path != "/login")
					{
						vm.$router.push("/login");
					}
				}
				else
				{
					this.CORS("GET", "/authenticate", "", function()
					{
						// var user = JSON.parse(response.user);

						// for(var i=0; i<user.actions.length; i++)
						// {
						// 	if(!vm.acl.includes(user.actions[i]["constant"]))
						// 	{
						// 		vm.acl.push(user.actions[i]["constant"]);
						// 	}
						// }
					},
					function()
					{
						vm.setLogin(false);
						vm.setSessionID("");

						if(vm.$route.path != "/login")
						{
							vm.$router.push("/login");
						}
					});
				}
			},
			setLogin: function(value)
			{
				this.logged_in = value;

				if(this.logged_in)
				{
					this.loadPolicies();
				}
			},
			setSessionID: function(value)
			{
				this.session_id = value;
				localStorage.setItem("session_id", value);
			},
			loadPolicies: function()
			{
				if(!this.logged_in)
				{
					return;
				}

				var vm = this;
				vm.loading = true;

				this.CORS("GET", "/policies", null,
				function(response)
				{
					vm.policies = response;

					if(vm.policies.length == 0)
					{
						return;
					}

					if(!vm.active_policy)
					{
						vm.active_policy = vm.policies[0].policynum;
					}

					// If there is only one policy and the user is on the dashboard, redirect them.
					if(vm.policies.length == 1 && window.location.pathname == "/")
					{
						vm.$router.push("/policy/" + vm.policies[0].policynum);
					}
				}, function()
				{
					vm.loading = false;
				});
			},
			setPolicy: function(p)
			{
				if(p.policynum == this.active_policy)
				{
					return;
				}

				this.active_policy = p.policynum;

				if(this.$route.name == "Documents")
				{
					this.$router.push("/documents/" + p.policynum);
				}
				else if(this.$route.name == "Pinkslips")
				{
					this.$router.push("/pinkslips/" + p.policynum);
				}
				else
				{
					this.$router.push("/policy/" + p.policynum);
				}
			}
		},
		mounted: function()
		{
			var vm = this;

			vm.checkSession();

			if(vm.logged_in)
			{
				vm.loadPolicies();
			}

			setInterval(function()
			{
				vm.checkSession();
			}, 60000);
		},
		watch:
		{
			'$route' () {
				this.checkSession();

				if(this.logged_in)
				{
					this.loadPolicies();
				}
			}
		}
	}
</script>

<style>
#app
{
	font-family: Avenir, Helvetica, Arial, sans-serif;
}
footer nav
{
	background: #fff;
}
footer a
{
	color: inherit;
}
.nowrap
{
	white-space: nowrap;
}

#app .niceblue a
{
	color: rgb(14, 117, 190);
}

#app .niceblue a em
{
	color: initial;
}

#app .pinkslip,
#app .niceblue a em.pinkslip
{
	color: rgb(255, 179, 204);
}


#app h1
{
	font-size: 1.5rem;
}
#app h2
{
	font-size: 1.15rem;
}

@media (min-width: 544px)
{
	#app h1
	{
		font-size: 1.75rem;
	}
	#app h2
	{
		font-size: 1.25rem;
	}
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px)
{
	#app h1
	{
		font-size: 2rem;
	}
	#app h2
	{
		font-size: 1.5rem;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px)
{
	#app h1
	{
		font-size: 2.25rem;
	}
	#app h2
	{
		font-size: 1.75rem;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px)
{
	#app h1
	{
		font-size: 2.5rem;
	}
	#app h2
	{
		font-size: 2rem;
	}
}
</style>