import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

	const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/policy/:policynum',
		name: 'Policy',
		component: () => import(/* webpackChunkName: "policy" */ '../views/Policy.vue')
	},
	{
		path: '/pinkslips/:policynum',
		name: 'Pinkslips',
		component: () => import(/* webpackChunkName: "pinkslips" */ '../views/Pinkslips.vue')
	},
	{
		path: '/documents/:policynum',
		name: 'Documents',
		component: () => import(/* webpackChunkName: "documents" */ '../views/Documents.vue')
	},
	{
		path: '/document/:document_id',
		name: 'Document',
		component: () => import(/* webpackChunkName: "document" */ '../views/Document.vue')
	},
	{
		path: '/numbers',
		name: 'Numbers',
		component: () => import(/* webpackChunkName: "numbers" */ '../views/Numbers.vue')
	},
	{
		path: '/account',
		name: 'Account',
		component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
