<template>
	<div>
		<div class="row mb-3">
			<div class="col-12">
				<h1><em class="far fa-address-book fa-flip-horizontal"></em> Welcome</h1>
			</div>
		</div>

		<div class="alert alert-info text-center"><em class="fas fa-info-circle"></em> Please choose a policy from the list above.</div>
	</div>
</template>

<script>
export default
{
	name: 'Home',
	props: ["session_id", "logged_in"],
	mounted: function()
	{
		if(!this.logged_in)
		{
			return;
		}
	}
}
</script>
